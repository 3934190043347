import './App.css';
import React, { useState, useEffect, useRef } from 'react';
import data from './data.json';
import { useForm, ValidationError } from '@formspree/react';
import { Helmet } from 'react-helmet';


function App() {

  const aboutRef = useRef(null);
  const projectsRef = useRef(null);
  const contactRef = useRef(null);

  const scrollTo = (i) => {

    if (i == 0) {
      aboutRef.current.scrollIntoView({
        behavior: 'smooth', // Smooth scrolling
        block: 'start',     // Aligns About at the top of the viewport
      });
    } 
    else if (i == 1) {
      projectsRef.current.scrollIntoView({
        behavior: 'smooth', // Smooth scrolling
        block: 'start',     // Aligns About at the top of the viewport
      });
    } 
    else if (i == 2) {
      contactRef.current.scrollIntoView({
        behavior: 'smooth', // Smooth scrolling
        block: 'start',     // Aligns About at the top of the viewport
      });
    }
  }

  useEffect(() => {
    document.title = "Tyson's Portfolio";
  }, []);

  return (
    <>
    <div className='site custom-scroll'>
        <Helmet>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <Header scrollToAbout={() => scrollTo(0)} scrollToProjects={() => scrollTo(1)} scrollToContact={() => scrollTo(2)}/>
        <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
          <About aboutRef={aboutRef} />
          <br></br><br></br><br></br>
        </div>
    </div>
    </>

  );
}

//<Projects projectsRef={projectsRef} />
//<Contact contactRef={contactRef} />

function RotatingCube() {
  const [rotation, setRotation] = useState({ x: 0, y: 0 });

  useEffect(() => {
    let animationFrame;

    const rotate = () => {
      setRotation((prev) => ({
        x: prev.x + 0.5, // Increment X-axis rotation
        y: prev.y + 0.5, // Increment Y-axis rotation
      }));
      animationFrame = requestAnimationFrame(rotate);
    };

    rotate(); // Start the animation

    return () => cancelAnimationFrame(animationFrame); // Cleanup on unmount
  }, []);
  const faces = [
    { className: "front", char: "$" },
    { className: "back", char: "#" },
    { className: "left", char: "%" },
    { className: "right", char: "&" },
    { className: "top", char: "#" },
    { className: "bottom", char: "@" },
  ];
  return (
    <div className="container">
      <div
        className="cube"
        style={{
          transform: `rotateX(${rotation.x}deg) rotateY(${rotation.y}deg)`, // Multi-axis rotation
        }}
      >
        {faces.map((face, index) => (
          <div className={`aboutText face ${face.className}`} key={index}>
            {Array.from({ length: 100 }).map((_, idx) => (
              <span key={idx}>{face.char}</span>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}


function Header({scrollToAbout, scrollToProjects, scrollToContact}) {


  function openLink(link) {
    if (link) window.open(link , "_blank");
  }
  return (
    <div className="header" >
      <h1 className={`logo`}>Tyson Jelicich</h1>
      <p className='title'>Back-end Engineer</p>
      <div className='nav'>
        <button type='button' className='button p1' >About</button>
        <button type='button' className='button p1' onClick={() => openLink("https://github.com/OldMateTys/")}>Projects</button>
        <button type='button' className='button p1' onClick={() => openLink("https://www.linkedin.com/in/tyson-jelicich-a7ba32184/")}>Contact</button>
      </div>
      <RotatingCube />
      <Icons />
    </div>
  );
}

function Icons() {
  const cv = "newspaper.svg";
  const gh = "github.svg";
  const li = "linkedin.svg";
  const cvPath = cv ? require(`./resources/${cv}`) : null;
  const ghPath = gh ? require(`./resources/${gh}`) : null;
  const liPath = li ? require(`./resources/${li}`) : null

  function openLink(link) {
    if (link) window.open(link , "_blank");
  }

  return(
    <div className='iconContainer'>
      <img src={cvPath} className='icon' alt='CV' />
      <img src={ghPath} className='icon' alt='Github' onClick={() => openLink("https://github.com/OldMateTys/")}/>
      <img src={liPath} className='icon' alt='LinkedIn' onClick={() => openLink("https://www.linkedin.com/in/tyson-jelicich-a7ba32184/")}/>
    </div>
  );
}

function Projects({ projectsRef }) {

  const [jsonData, setJsonData] = useState(data);
  const [zoom, setZoom] = useState(1);

  function handleZoomChange(e) {
    setZoom(Number(e.target.value));
  }

  function Tile({i, onClick}) {
    const projectHeading = jsonData[i].title;
    const prjDescription = jsonData[i].description;
    const projectImage = jsonData[i].image;

    const imagePath = projectImage ? require(`./resources/${projectImage}`) : null;



    return (
      <div className='tile' onClick={onClick}>
        <div className='imageContainer'>
          <img src={imagePath} alt="Stock chart" className='tileImage' />
        </div>
        
        <div className='tileTextBox'>
          <div className='p tileTitle'>{projectHeading}</div>
          <div className='p tileText'>{prjDescription}</div>
        </div>

      </div>
    );

  }

  function handleClick(i) {
    const link = jsonData[i].link;

    if (link) window.open(link , "_blank");
  }


  return (
    <div className='main'>
      <div className='block'>
        <div className='headingContainer'>
          <div className='p heading' ref={projectsRef}>Projects</div>
      
          <div className='tileContainer'>
            <Tile i={0} onClick={() => handleClick(0)} />
            <Tile i={1} onClick={() => handleClick(1)} />
            
          </div>
        </div>
      </div>
    </div>
  );
  
}

function About({aboutRef}) {

  const [ isOn, setIsOn ] = useState(true);
  const [ isFading, setIsFading ] = useState(false);
  const [ textBool, setTextBool] = useState(true);
  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState('auto');

  const toggleState = () => {
    setIsFading(true);
    setIsOn(!isOn);
    setTimeout(() => {
      
      setIsFading(false);
      setTextBool(!textBool);
    }, 300);
  };

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(`${contentRef.current.scrollHeight}px`);
    }
  }, [textBool]);

  return (
    <div className='main'>
      <div className='block'>
        <div className='title_box'>
          <div className='p heading' ref={aboutRef}>About</div>
          <div className='sliding-bar-container' onClick={toggleState}>
            <div className={`sliding-bar ${isOn ? 'active' : 'inactive'}`}></div>
            <div className='labelContainer'>
              <p className='label label1'>Overview</p>
              <p className='label label2'>TL;DR</p>
            </div>
          </div>
        </div>
      <br />
        <div 
          className={`content-wrapper ${isFading ? 'fade-out' : ''}`}
          style={{height: contentHeight}}>
          <div ref={contentRef}>{textBool ? aboutMe : tldr}</div>
          
        </div>
      </div>    
    </div>
  );
}

function Contact({contactRef}) {
  return (
    <div className='main'>
        <div className='block'>
          <div className='headingContainer'>
          <div className='p heading' ref={contactRef}>Contact</div>
          </div>
          <div className='aboutText'>
            <br></br>
            Shoot me a message below if you wish to get in touch. Cheers!
            <br></br><br></br><br></br>
          </div>
          <ContactForm />
        </div>
    </div>
  );
}

function ContactForm() {
  const [state, handleSubmit] = useForm("myzynzwo");
  if (state.submitting) {
    return (
      <div className='bold'>Submitting...</div>
    );
  }
  if (state.succeeded) {
      return <p className='bold'>Received! Thanks for reaching out! I'll be in touch soon.</p>;
  }
  return (
    <form onSubmit={handleSubmit} className='form'>
      <div className='contactBox'>
      <div className='prompt-box'>
      <label htmlFor="email" className='bold'>
        Email Address:&nbsp;&nbsp;
      </label>
      <textarea
        id="email"
        name="email"
        className='emailInput'
      />
      <ValidationError 
        prefix="Email" 
        field="email"
        errors={state.errors}
      />
      </div>
      <div className='prompt-box'>
        <div>
      <label htmlFor="email" className='bold'>
        Message:&nbsp;&nbsp;
      </label>
      </div>
      <div>
      <textarea
        id="message"
        name="message"
        
      />
      
      <ValidationError 
        prefix="Message" 
        field="message"
        errors={state.errors}
      />
      </div>
      </div>
      <div className='submitButtonBox'>
      <button type="submit" disabled={state.submitting} className='submitButton'>
        Submit
      </button>
      </div>
      </div>
    </form>
  );
}

const aboutMe = (
  <div className='aboutText'>

I’m a dedicated Computer Science student at the University of Sydney with a finance background, driven by a passion for continuous learning and problem-solving.
<br></br><br></br>
Currently, I’m exploring high-performance, low-latency systems, delving into competitive programming to sharpen my algorithmic skills, and honing my expertise in Python, Java, and C++.
<br></br><br></br>
In my current capacity and experience, I have led projects such as introducing and managing new CRM systems, automating compliance workflows to reduce manual input, and developing data modeling tools that offer real-time analytics to end users. 
<br></br><br></br>
I am currently expanding my skillset and knowledge through reading advanced technical literature (e.g., books on low-level programming, algorithmic design, and systems optimisation), participating in competitive programming challenges to sharpen problem-solving under time constraints, and engaging in hobby-projects to keep up-to-date with emerging trends and best practices.
<br></br><br></br>
I’m actively pursuing opportunities to apply my expanding skillset to real-world challenges and refine my approach to performance-critical solutions. Whether through internships, part-time roles, or collaborative projects, I aim to contribute meaningfully alongside seasoned professionals and continue growing as a developer.
<br></br><br></br>
If you’re looking for a dedicated candidate with this background, please feel free to reach out.
  </div>
);
const tldr = (
  <div className='aboutText'>

I am currently studying Computer Science.
<br></br><br></br>
I have a background in Finance.
<br></br><br></br>
I am driven and passionate.
<br></br><br></br>
I have a strong record of academic excellence.
<br></br><br></br>
I have worked extensively in collaborative roles and environments.
<br></br><br></br>
I am available to hire, and actively seeking more experience.
<br></br><br></br>
If you have an available position and value the above, reach out.
  </div>
);

export default App;
